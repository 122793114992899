import React, { useEffect, useState } from 'react';
import './home.css';
import { Crosswalk, CrosswalkComponent } from '../simulation/crosswalk';

const App = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('https://bse-backend.ulozezoz.myhostpoint.ch/API/simpleAPI_Shipnames')
      .then(response => {
        if (!response.ok) { throw Error(response.statusText); }
        return response.json();
      })
      .then(data => {
        setData(data);
        localStorage.setItem('data', JSON.stringify(data));
      })
      .catch(() => {
        const localData = localStorage.getItem('data');
        if (localData) {
          setData(JSON.parse(localData));
        }
      });
  }, []);

  console.log(data);

  return (
    <div className="App">
      <header className="App-header">
        <h1>SimScript React PWA</h1>
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
      </header>
      <main className="App-main">
        <CrosswalkComponent
          key='xwlk-anim'
          sim={new Crosswalk({ slowMode: true })}
          animated='svg' />
          {/* Hier werden die Daten angezeigt */}
        {data && <div>{JSON.stringify(data)}</div>}
      </main>
      <footer className="App-footer">
        <p>
          Institute of Computational Life Sciences |
          Digital Labs & Production |
          Simulation & Optimization
        </p>
      </footer>
    </div>
  );
}

export default App;